const Content = {
  left: {
    imageSrc: 'left_img.jpg',
    headLine: 'ручная',
    linkTo: null,
    text: null
  },
  right: {
    imageSrc: 'right_img.jpg',
    headLine: 'вышивка',
    linkTo: null,
    text: null
  }
};

export default Content;
