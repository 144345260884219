const Content = [
  {
    id: '001',
    slug: 'item_001',
    name: 'Единорожка',
    price: 1600,
    imageSrc: 'item1.jpg',
    description: `Всем кто устал ещё с утра,
      но всё равно продолжает
      творить чудеса. 🦄`,
    category: ['shirts', 'футболки'],
    sizes: ['sm', 'm', 'xxl'],
    colors: [
      ['белый', '#fff'],
      ['черный', '#333'],
      ['серый', '#a3a9ac']
    ]
  },
  {
    id: '002',
    slug: 'item_002',
    name: 'Дарт Вейдер',
    price: 1900,
    imageSrc: 'item2.jpg',
    description: `Всем кто устал ещё с утра,
      но всё равно продолжает
      творить чудеса. 🦄`,
    category: ['shirts', 'футболки'],
    sizes: ['sm', 'l', 'xl'],
    colors: [['белый', '#fff']]
  },
  {
    id: '003',
    slug: 'item_003',
    name: 'Логотип',
    price: 1500,
    imageSrc: 'item3.jpg',
    description: `Всем кто устал ещё с утра,
      но всё равно продолжает
      творить чудеса. 🦄`,
    category: ['sweatshots', 'свитшоты'],
    sizes: ['sm', 'm', 'xl'],
    colors: [
      ['белый', '#fff'],
      ['серый', '#a3a9ac']
    ]
  },
  {
    id: '004',
    slug: 'item_004',
    name: 'Коробка Любви',
    price: 2500,
    imageSrc: 'item4.jpg',
    description: `Всем кто устал ещё с утра,
      но всё равно продолжает
      творить чудеса. 🦄`,
    category: ['hoodies', 'худи'],
    sizes: ['xxs', 'sm', 'l'],
    colors: [
      ['черный', '#333'],
      ['серый', '#a3a9ac']
    ]
  },
  {
    id: '005',
    slug: 'item_005',
    name: 'FACT',
    price: 4000,
    imageSrc: 'item5.jpg',
    description: `Всем кто устал ещё с утра,
      но всё равно продолжает
      творить чудеса. 🦄`,
    category: ['sweatshots', 'свитшоты'],
    sizes: ['xs', 'sm', 'xl'],
    colors: [['серый', '#a3a9ac']]
  },
  {
    id: '006',
    slug: 'item_006',
    name: 'Котики',
    price: 1400,
    imageSrc: 'item6.jpg',
    description: `Всем кто устал ещё с утра,
      но всё равно продолжает
      творить чудеса. 🦄`,
    category: ['hoodies', 'худи'],
    sizes: ['sm'],
    colors: [['черный', '#333']]
  },
  {
    id: '007',
    slug: 'item_007',
    name: 'Авокадо',
    price: 1300,
    imageSrc: 'item7.jpg',
    description: `Всем кто устал ещё с утра,
      но всё равно продолжает
      творить чудеса. 🦄`,
    category: ['sweatshots', 'свитшоты'],
    sizes: ['m'],
    colors: [['белый', '#fff']]
  },
  {
    id: '008',
    slug: 'item_008',
    name: 'Коробка для двоих',
    price: 2900,
    imageSrc: 'item8.jpg',
    description: `Всем кто устал ещё с утра,
      но всё равно продолжает
      творить чудеса. 🦄`,
    category: ['hoodies', 'худи'],
    sizes: ['sm', 'm', 'xxl'],
    colors: [
      ['белый', '#fff'],
      ['черный', '#333'],
      ['серый', '#a3a9ac']
    ]
  },
  {
    id: '009',
    slug: 'item_008',
    name: 'Сердечко',
    price: 1300,
    imageSrc: 'item9.jpg',
    description: `Всем кто устал ещё с утра,
      но всё равно продолжает
      творить чудеса. 🦄`,
    category: ['hoodies', 'худи'],
    sizes: ['m', 'xl'],
    colors: [
      ['черный', '#333'],
      ['серый', '#a3a9ac']
    ]
  }
];

export default Content;

// item_00: {
//   id: '000', //string
//   slug: 'item_000', //string
//   name: 'Единорожка', //string
//   price: 1600, //num
//   imageSrc: 'item0.jpg', //string
//   description: `Всем кто устал ещё с утра, //string
//   но всё равно продолжает
//   творить чудеса. 🦄`,
//   category:['футболки',''] // [slug:string,name:string]
// }
